//*
//*
//*
//*
// Global strings for the site

// Name of the dealership
export const DEALERSHIP_NAME = "Saini Auto Sales"

// Address for the dealership
export const ADDRESS = "885 Gravenstein Hwy North Suite 3 Sebastopol, CA 95472"

// Phone number for the dealership
export const PHONE_NUMBER = "(707) 827-3258"

// Email address for the dealership
export const EMAIL = "sales@sainiautosales.com"

// Hours of operation as shown in footer
export const HOURS = [
  { day: "M-S", hours: "10:00AM - 6:00PM" },
  { day: "Sun", hours: "Closed" },
]

// This is the embed url from Google Maps, to generate a new one, go to Google Maps, search for your location, click the share button, and copy the embed url
export const MAP_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3126.3615633842896!2d-122.8400707!3d38.410008399999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80843baca14ac5db%3A0x5bc4627a0dd146f5!2sSaini%20Auto%20Sales!5e0!3m2!1sen!2sus!4v1706892025899!5m2!1sen!2sus"

export const DISCLAIMER = `The estimated monthly payments and estimated down
payments listed on this inventory page are based on a 720 credit
score and a 72 month term with $100,000 annual gross income.
Actual terms may vary based on individual creditworthiness, loan
amount, and length of term. Prices listed may exclude additional
fees and taxes. Please contact us for more information on
financing options and terms.
`
//*
//*
//*
//*
// Homepage strings

// Tagline for the homepage, this should be a slogan or a catchy phrase.
export const TAGLINE = "Great Vehicles at Low Prices!"

// This is the link for the google maps directions link. To generate one go to google maps, enter the location, click directions, and copy the link in the address bar. This way the user will go to the directions page from their location to the dealership location
export const MAP_LINK_URL =
  "https://www.google.com/maps/place/Saini+Auto+Sales/@38.4100084,-122.8414796,17z/data=!3m1!4b1!4m6!3m5!1s0x80843baca14ac5db:0x5bc4627a0dd146f5!8m2!3d38.4100084!4d-122.8400707!16s%2Fg%2F11g0vzr1c7?entry=ttu"

// This is dummy data. Replace with dealership testimonials.
export const TESTIMONIALS = [
  {
    name: "Jada Smith",
    title: "Satisfied Customer",
    testimonial:
      "I just got a car here that’s in very good condition for a really fair price. About a week after purchasing, I realized the car’s starter wouldn’t always work. I called Bobby, and without hesitation, he paid to get it fixed. Overall an amazing experience.",
    rating: 5,
  },
  {
    name: "Lori",
    title: "Happy Buyer",
    testimonial:
      "I bought a car from Saini Auto Sales a few days ago. Mohammed was so easy to deal with. The windshield had a small chip, and they replaced the windshield for me. The car is in great shape and the price was very fair. It was exactly what I wanted. I highly recommend them!",
    rating: 5,
  },
  {
    name: "S Daley",
    title: "Happy Buyer",
    testimonial:
      "Bobby, and his brother, were great to work with.  As others have said, Bobby is very relaxed, informative and open.  No pressure at all,  The car itself that we purchased is immaculate and a joy to drive.  We thought the price very fair.  Would do it all over again.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "Happy Buyer",
    testimonial:
      "We purchased a vehicle from Bobby in August 2022. Bobby made the entire process quick and easy. He asked us to let him know if anything went wrong with the vehicle after our purchase and he would take care of it; he 100% stood by his word as the air conditioning system needed recharging. Thank you Bobby for being an honest business person and a pleasure to work with!",
    rating: 5,
  },
  {
    name: "Michael Ruddick",
    title: "Happy Buyer",
    testimonial:
      "I had a very positive experience buying a used car here recently.  As others have said, Bobby is a mellow guy, no stereotypical used car sales tactics or pressure.  He seems to keep an eye for better than average, or even better, cars to put onto his lot.  As others have stated, because he can handle all of the DMV work from his office there on the lot, it's super easy.  I got a fair deal, I think he knows the value of what he is selling, and he knows he has better-than-typical used car quality.  It's a bummer to be buying a used car these days, given what the market is doing, but at least Bobby made it easy :)",
    rating: 5,
  },
  {
    name: "Colin Horn",
    title: "Happy Buyer",
    testimonial:
      "Bobby was super helpful. No questionable selling tactics or pressure from him to make a sell. He was very honest and supportive of me taking the time I needed to make my decision. He allowed me ample time to test drive and take the car I was looking at to a mechanic of my choosing. Buying a used car can be a daunting experience, and he made it as pleasant as possible for me. Straight up honest guy. Plus it felt good to support a local small business owner. I feel 100% comfortable recommending him to any friends and family. Thanks Bobby!",
    rating: 5,
  },
  {
    name: "Nate Stephens",
    title: "Happy Buyer",
    testimonial:
      "My 2005 Ford Focus recently sustained more damage than I could justify repairs on. Jason (mechanic) mentioned that Saini Auto Sales had high quality  vehicles, and that they were professional and easy to work with. I appreciated the recommendation. The moment I stepped onto the modest sized lot, I liked what I saw. A wide variety of nice looking cars. Bobby, the owner greeted me with a warm professional greeting. I didn't feel any pressure at all. I noticed that he kept meticulous records and Carfax reports on all of his vehicles. He also let me know that he would take care of the registration and DMV processing so that I wouldn't have to worry about it. This is a nice addition, considering the fact that there is no extra charge for the service. After some test driving and a little consideration, I purchased a 2012 Honda Civic. The process was seamless, and I feel great about my decision! It was a pleasure doing business with Saini Auto to be sure!",
    rating: 5,
  },
  {
    name: "Dennis DeLuca",
    title: "Happy Buyer",
    testimonial:
      "5 stars! Had a wonderful experience purchasing a used Toyota Camry from owner Bobby of Saini Auto Sales!  A month later I am still completely satisfied with the car and the overall experience.  I shopped around quite a bit online and found a great deal here.  In fact, so much so, I drove roughly 3 hours to buy a car here and do not regret it one bit.  Bobby was no pressure, honest, and a good guy.  He gave me ample time to look the car over and test drive it.  He answered all my questions and told me what he knew about the car, including providing me with a Car Fax on it.  At purchase, Bobby gave me the option for him to handle all the DMV paper which I took him up on.  Glad I did, he did the paperwork promptly and it made the process so much easier.  Also, after the sale, he maintained good communication on the phone with me keeping me updated on paper work status and on a minor part (speaker covers) that were ordered for the car.  Definitely recommend Saini Auto Sales.  Nice clean lot, nice selection of quality used cars, good prices, and Bobby is top notch to deal with.",
    rating: 5,
  },
  {
    name: "Connor Hickey",
    title: "Happy Buyer",
    testimonial:
      "Super late review, I bought a Toyota Sienna here a couple years ago. I have nothing but good things to say and highly recommend this business. Its lasted me this entire time without a single issue, and the man who assisted me really helped me out with the price after learning I intended to live in it while traveling the country. This was the first car purchase I’ve made where I actually felt good and confident in my purchase. I no longer live in California, but I will certainly come back here for my next car purchase. Finding a trustworthy car lot who cares about their customers isn’t easy, and I’m glad I decided to do business with Saini Auto.",
    rating: 5,
  },
  {
    name: "Victoria Sanchez",
    title: "Happy Buyer",
    testimonial:
      "After looking at used cars advertised on Craigslist, visiting a used car dealer in Oakland and calling other dealerships, I can safely say that my experience at Saini Auto Sales was the best by far. Bobby is really fair with pricing, he’s honest, and he has great contacts with local mechanics that are very reasonable. Weeks after we purchased the car from him, he even went out of his way to introduce us to someone who could repair some minor cosmetic work on the car at a reasonable price. He’s fantastic and I recommend him highly. Do yourself a favor and buy your next used car from Saini Auto.",
    rating: 5,
  },
]

//*
//*
//*
//*
// Contact Us Page strings
export const CONTACT_FORM_TITLE = "Contact Us"
export const CONTACT_FORM_SUBTITLE =
  "We'd love to hear from you. Contact us using the details below!"
export const CON_CONTACT_TITLE = "Visit Our Dealership"

//*
//*
//*
//*
// Financing Page strings
export const FINANCING_TITLE = "Contact Us"
export const FINANCING_SUBTITLE =
  "We'd love to hear from you. Contact us using the details below or fill out the form."
export const FIN_CONTACT_TITLE = "Visit Our Dealership"
