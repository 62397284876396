/** @jsxImportSource theme-ui */
import React, { useState } from "react"
import { Box, Flex, Switch, useColorMode, Button, Text } from "theme-ui"
import { Link } from "gatsby"
import {
  navStyles,
  logoStyles,
  buttonStyles,
  ulStyles,
  closeButtonStyles,
  linkStyles,
  switchStyles,
  switchButtonStyles,
  flexStyles,
  navLinkStyles,
  activeLinkStyles,
  logoContainerStyles, // Catch those waves with the active styles, dude
} from "./header-1-Styles"
//import { StaticImage } from "gatsby-plugin-image"
import { DEALERSHIP_NAME } from "../../config/strings"

/**
 * @component Header
 * @description The header of the site, the gateway to the surf spots!
 * @returns {JSX.Element} The header component, get ready to ride!
 */
const Header1 = () => {
  const [colorMode, setColorMode] = useColorMode()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  /**
   * @function handleToggle
   * @description Flips the color mode switch, like waxing the board!
   * @param {object} e - The event object, like a fresh breeze.
   */
  const handleToggle = e => {
    setColorMode(e.target.checked ? "dark" : "light")
  }

  /**
   * @function toggleMenu
   * @description Like the tides, it opens and closes the mobile menu!
   */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleButtonClick = () => {
    window.open(
      "https://www.google.com/search?q=saini+auto+sales&rlz=1C1VDKB_enUS1068US1068&oq=saini&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgwIARAAGAoYsQMYgAQyCQgCEEUYORiABDIHCAMQABiABDIPCAQQABgKGIMBGLEDGIAEMgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEIMTkzNWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x80843baca14ac5db:0x5bc4627a0dd146f5,1,,,,",
      "_blank"
    )
  }

  const menuItems = ["/", "/inventory", "/contact"]
  const labels = ["Home", "Inventory", "Contact"]

  return (
    <Flex as="nav" sx={navStyles}>
      <div sx={logoContainerStyles}>
        <Link to="/">
          <Text sx={{ color: "onBackground" }} as="h1">
            {DEALERSHIP_NAME}
          </Text>
        </Link>
      </div>

      <Button onClick={toggleMenu} sx={buttonStyles}>
        ☰
      </Button>

      <Flex sx={flexStyles}>
        {menuItems.map((path, index) => (
          <Link to={path} activeClassName="activeLink" key={index}>
            <Button
              sx={{ ...navLinkStyles, ".activeLink &": activeLinkStyles }}
            >
              {labels[index]}
            </Button>
          </Link>
        ))}
        <Button mr={3} onClick={handleButtonClick}>
          Read Reviews
        </Button>
        <Box sx={switchStyles}>
          <Switch
            checked={colorMode === "dark"}
            onChange={handleToggle}
            sx={switchButtonStyles}
          />
        </Box>
      </Flex>

      {isMenuOpen && (
        <Box as="ul" sx={ulStyles}>
          <Button onClick={toggleMenu} sx={closeButtonStyles}>
            ×
          </Button>
          {menuItems.map((path, index) => (
            <Link to={path} activeClassName="activeLink" key={index}>
              <Button
                sx={{ ...linkStyles, ".activeLink &": activeLinkStyles }}
                onClick={toggleMenu}
              >
                {labels[index]}
              </Button>
            </Link>
          ))}
          <Button mr={3} onClick={handleButtonClick}>
            Read Reviews
          </Button>
          <Box sx={switchStyles}>
            <Switch
              checked={colorMode === "dark"}
              onChange={handleToggle}
              sx={switchButtonStyles}
            />
          </Box>
        </Box>
      )}
    </Flex>
  )
}

export default Header1
