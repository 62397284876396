/**

    The styles for the BottomBar component, dude!
    Defines the styles for the footer at the bottom of the page, bro!
    */
export const bottomBarStyles = {
  bg: "onSecondaryFixedVariant",
  color: "primaryFixed",
  p: 3,
  mt: "auto", // Stick to the bottom, bro!
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: ["center", "center", "flex-start"],
}
