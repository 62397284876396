const styles = {
    layout: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      width: '100%',
      flex: '1 1 auto',
      bg: 'background',
      color: 'onBackground',
    },
  };
  
  export default styles;
